// import React, { useState } from "react"
// import StyledHistory from "./style/history"
// import CTA from "../common/CTA"

// import CountUp from "react-countup"
// import ScrollTrigger from "react-scroll-trigger"

// const History = ({ data }) => {
//   const [counter, setCounter] = useState(false)
//   return (
//     <StyledHistory>
//       <ScrollTrigger onEnter={() => setCounter(true)}>
//         <div className="main-container">
//           <div className="wrapper">
//             <div className="projects">
//               <span>
//                 {counter && (
//                   <CountUp
//                     end={data.projects.totalProjects}
//                     duration={4}
//                     className="2xl:text-[56px] sm:text-[30px] text-[25px]"
//                   />
//                 )}
//                 +
//               </span>
//               <h6>{data.projects.heading}</h6>
//             </div>
//             {/* <div
//               className="process"
//               style={{ backgroundImage: `url(${data.process.bgImage})` }}
//             >
//               <span>
//                 <img src={data.process.image} alt={data.process.heading} />
//                 {data.process.heading}
//               </span>
//               <p>{data.process.description}</p>
//             </div> */}
//             <div className="clients">
//               <span>
//                 {counter && (
//                   <CountUp
//                     end={data.clients.totalClients}
//                     duration={4}
//                     className="2xl:text-[56px] sm:text-[30px] text-[25px]"
//                   />
//                 )}
//                 +
//               </span>
//               <h6>{data.clients.heading}</h6>
//             </div>
//             {/* <div className="team">
//               <span>
//                 <img src={data.team.image} alt={data.team.heading} />
//                 {data.team.heading}
//               </span>
//               <p>{data.team.description}</p>
//             </div>
//             <div className="security">
//               <span>
//                 <img src={data.security.image} alt={data.security.heading} />
//                 {data.security.heading}
//               </span>
//               <p>{data.security.description}</p>
//             </div> */}
//           </div>
//           <CTA link={data.cta.slug} type="primary" text={data.cta.text} />
//         </div>
//       </ScrollTrigger>
//     </StyledHistory>
//   )
// }

// export default History

import React, { useState } from "react"
import CountUp from "react-countup"
import ScrollTrigger from "react-scroll-trigger"

const Counter = ({ data }) => {
  const [counter, setCounter] = useState(false)

  return (
    <ScrollTrigger
      onEnter={() => setCounter(true)}
      className="3xl:pt-[71px] 2xl:pt-[60px] pt-[10px] pb-20 xl:flex grid xl:justify-center xl:space-x-10 justify-center space-y-5 xl:space-y-0 text-black "
    >
      <h2 className="flex items-center">
        {counter && (
          <CountUp
            end={data.years.totalYears}
            duration={3}
            className="2xl:text-[56px] sm:text-[30px] text-[25px]"
          />
        )}
        +
        <span className="ml-4 xl:w-[30%] tracking-wider">
          {data.years.heading}
        </span>
      </h2>
      <h2 className="flex items-center">
        {counter && (
          <CountUp
            end={data.projects.totalProjects}
            duration={3}
            className="2xl:text-[56px] sm:text-[30px] text-[25px]"
          />
        )}{" "}
        +
        <span className="ml-4 xl:w-[50%] tracking-wider">
          {data.projects.heading}
        </span>
      </h2>
      <h2 className="flex items-center">
        {counter && (
          <CountUp
            end={data.clients.totalClients}
            duration={3}
            className="2xl:text-[56px] sm:text-[30px] text-[25px]"
          />
        )}
        +
        <span className="ml-4 xl:w-[60%] tracking-wider">
          {data.clients.heading}
        </span>
      </h2>
    </ScrollTrigger>
  )
}

export default Counter
